import { NavItem, RouteData } from '@environments/environment.interface';

import { Injectable } from '@angular/core';
import { ManageablePartnerIds } from '@core/models/auth.model';
import { Roles } from '@core/enum/roles.enum';
import { uniq } from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private manageablePartnerIds!: ManageablePartnerIds;
	private roles: Roles[] = [];
	private partnerId!: string;
	private userId!: string;

	/**
	 * @description Check if current user is allowed to access provided contract
	 */
	public canManageData(value: string): boolean {
		const permissionList = [...(this.manageablePartnerIds?.BTRDataManager || []), this.partnerId];
		return permissionList.includes(value);
	}
	/**
	 * @description Check if current user is allowed to access provided contract
	 */
	public canManageContract(value: string): boolean {
		const permissionList = [...(this.manageablePartnerIds?.BTRContractManager || []), this.partnerId];
		return permissionList.includes(value);
	}
	/**
	 * @description Check if current user has Admin role
	 */
	public hasAdminRole(): boolean {
		const roles = this.getRoles();
		const permissionList = [Roles.ADMIN_ROLE];
		return permissionList.every(role => roles.includes(role));
	}
	/**
	 * @description Check if current user is has product pass manager role
	 */
	public hasProductPassManagerRole(): boolean {
		const roles = this.getRoles();
		const permissionList = [Roles.PRODUCT_PASS_MANAGER_ROLE];
		return permissionList.every(role => roles.includes(role));
	}

	/**
 * @description Check if current user is has product pass manager role
 */
	public hasProductPassApproverRole(): boolean {
		const roles = this.getRoles();
		const permissionList = [Roles.PRODUCT_PASS_APPROVER_ROLE];
		return permissionList.every(role => roles.includes(role));
	}

	public isContractManager(): boolean {
		return !!this.manageablePartnerIds?.BTRContractManager || this.roles.includes(Roles.CONTRACT_MANAGER_ROLE);
	}
	public isDataManager(): boolean {
		return !!this.manageablePartnerIds?.BTRDataManager || this.roles.includes(Roles.DATA_MANAGER_ROLE);
	}

	public setManageablePartnerIds(val: ManageablePartnerIds): void {
		this.manageablePartnerIds = val;
	}

	public updateRolesByManageablePartnerIds(): void {
		if (this.isContractManager()) {
			this.roles = uniq([...this.roles, Roles.CONTRACT_MANAGER_ROLE]);
		}
		if (this.isDataManager()) {
			this.roles = uniq([...this.roles, Roles.DATA_MANAGER_ROLE]);
		}
	}

	public setUserId(id: string): void {
		this.userId = id;
	}

	public getUserId(): string {
		return this.userId;
	}

	public setRoles(roles: Roles[]): void {
		this.roles = roles;
	}
	public setPartnerId(partnerId: string): void {
		this.partnerId = partnerId;
	}
	public getPartnerId() {
		return this.partnerId;
	}
	public getManageablePartnerIds() {
		return this.manageablePartnerIds;
	}

	public getRoles(): Roles[] {
		return this.roles;
	}

	public showNavItem(navItem: NavItem): boolean {
		if (!navItem.canNavigateToRoles?.length) {
			return true;
		}
		return navItem.canNavigateToRoles?.some((role) => this.roles.includes(role));
	}

	public canNavigate(data: RouteData): boolean {
		if (!data.canNavigateToRoles?.length) {
			return true;
		}
		return data.canNavigateToRoles.some((role) => this.roles.includes(role));
	}
}
