<div *ngIf="fieldSettings.isVisible" class="notification-error container-border">
	<div class="box white" *transloco="let t">
		<div class="g-inline-notification margin-top-1rem g-inline-notification-warning">
			<div class="icon-wrapper align-item-center margin-right-05rem">
				<i class="g-icon g-icon-warning-filled-24 g-inline-notification-status"></i>
			</div>
		<span class="g-inline-notification-content margin-right-05rem">{{ t(fieldSettings.text) }}</span>
			<div *ngIf="!hasAdminRole" class="display-flex align-items-end justify-content-flex-end position-relative top-0 left-0">
				<groupui-link data-test="link-email" target="blank" href="mailto: {{ supportEmail }}">
					<groupui-icon class="padding-right-05rem" name="email-24" theme="vwag"></groupui-icon>
				</groupui-link>
			</div>
		</div>
	</div>
</div>
