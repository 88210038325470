import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { GroupUiButtonStateDirective } from '@core/directives/group-ui/group-ui-button-state.directive';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { ShowIfHasAdminRoleDirective } from '@core/directives/show-if-has-admin-role.directive';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';
import { environment } from '@environments/environment';

export interface InlineNotification {
	isVisible: boolean; // Controls visibility of the toast
	type: string;
	text: string;
	data?: string[];
}

export enum InlineNotificationType {
	INFO = 'info',
	WARN = 'warn',
}
@Component({
	selector: 'btr-inline-notification',
	templateUrl: './inline-notification.component.html',
	styleUrls: ['./inline-notification.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslocoRootModule, TranslocoTestingModule, GroupUiButtonStateDirective, GroupUiModule, ShowIfHasAdminRoleDirective],
})
export class InlineNotificationComponent {
	supportEmail = environment.config.contact.email;
	@Input() fieldSettings!: InlineNotification; // Notification data received from parent
	@Input() hasAdminRole!: boolean;
}
