import { AppEnvironment } from './environment.interface';
import { Environments } from '@core/enum/environments.enum';
import { NgxLoggerLevel } from 'ngx-logger';
import awsConfig from './awsConfig.json';
import { globalConfig } from './configs/globalConfig';

export const environment: AppEnvironment = {
	production: true,
	environment: Environments.DEV,
	backend: {
		graphql: {
			backendURLContractManagement: awsConfig.backendURLContractManagement,
			backendURLComponentManagement: awsConfig.backendURLComponentManagement,
			backendURLMasterDataManagement: awsConfig.backendURLMasterDataManagement,
			backendURLB2bAdapter: awsConfig.backendURLb2bAdapter,
		},
		auth: {
			cognito: {
				region: 'eu-west-1',
				identityPoolRegion: 'eu-west-1',
				userPoolId: awsConfig.cognitoUserPoolID,
				userPoolWebClientId: awsConfig.cognitoClientID,
				signUpVerificationMethod: 'code',
				oauth: {
					domain: awsConfig.cognitoURL.replace('https://', ''),
					scope: ['email', 'profile', 'openid'],
					responseType: 'code',
				},
			},
		},
	},
	mainUrl: awsConfig.frontendURL,
	mainUrlBaseHref: '',
	logging: {
		level: NgxLoggerLevel.TRACE,
	},
	config: {
		...globalConfig,
		serviceWorker: {
			...globalConfig.serviceWorker,
			enabled: true,
			// eslint-disable-next-line no-magic-numbers
			refreshTimerMS: 3 * 60 * 1000, //3 minutes
		},
		contact: {
			email: awsConfig.ctrSupportEmail
		},
	},
};
