import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { CopyValueDirective } from '@shared/directives/copy-value/copy-value.directive';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { RouterModule } from '@angular/router';
import { SanitizeHtmlPipe } from '@shared/pipes/sanitize/sanitize-html.pipe';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';
export interface NotificationCard {
	isOpen: boolean;
	type: NotificationCardType;
	title: string;
	optionalMessage?: string;
	contentText: string;
	copyContent?: CopyContent;
	parentCopyContent?: CopyContent;
	onClose?: () => void;
	primaryButton?: NotificationCardButton;
	secondaryButton?: NotificationCardButton;
	/**
	 * @description Payload is emitted in the outputs primaryClick and secondaryClick
	 */
	payload?: unknown;
	/**
	 * @description Prevents modal from being closed by default methods
	 */
	closeAble?: boolean;
	dangerButton?: boolean;
}

export enum NotificationCardType {
	SUCCESS = 'success',
	ERROR = 'error',
	INFO = 'info',
}

export interface CopyContent {
	copyText: string;
	copyValue: string;
}

export interface NotificationCardButton {
	icon?: string;
	label: string;
	/**
	 * @deprecated Use the outputs primaryClick and secondaryClick instead
	 */
	function?: () => void;
}
export const DEFAULT_SUCCESS_NOTIFICATION_CARD: NotificationCard = {
	isOpen: false,
	type: NotificationCardType.SUCCESS,
	title: 'SHARED.MODAL_TITLE#SUCCESS',
	contentText: '',
	primaryButton: {
		label: 'SHARED.BUTTON.CLOSE',
		icon: 'close-24',
	},
};
@Component({
	selector: 'btr-notification-card',
	templateUrl: './notification-card.component.html',
	standalone: true,
	imports: [CommonModule, RouterModule, GroupUiModule, ClipboardModule, CopyValueDirective, SanitizeHtmlPipe, TranslocoRootModule, TranslocoTestingModule],
})
export class NotificationCardComponent {
	@Input() fieldSettings!: NotificationCard;
	@Output() fieldSettingsChange = new EventEmitter<NotificationCard>();
	@Output() primaryClick = new EventEmitter();
	@Output() secondaryClick = new EventEmitter();
	closeModal(closeFunction?: () => void) {
		this.fieldSettings.isOpen = false;
		if (closeFunction) {
			closeFunction();
		}
		this.fieldSettingsChange.emit(this.fieldSettings);
	}
}
