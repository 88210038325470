import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description Sanitizes HTML by removing dangerous tags like <img>, <script>, etc.
 * Keeps other tags intact and renders valid HTML.
 * Usage:
 * - `<div [innerHTML]="unsafeHtml | sanitizeHtml"></div>`
 */

@Pipe({
  name: 'sanitizeHtml',
  standalone: true,
})
export class SanitizeHtmlPipe implements PipeTransform {
  // Static list of tags to sanitize
  static readonly SANITIZE_LIST: string[] = ['img', 'script', 'style', 'iframe', 'object', 'embed', 'applet'];

  transform(value: string): string {
	let sanitizedValue = value;
    if (!value) {return '';}
    SanitizeHtmlPipe.SANITIZE_LIST.forEach((tag) => {
      const regex = new RegExp(`<${tag}[^>]*>.*?</${tag}>|<${tag}[^>]*\\/?>`, 'gi');
      sanitizedValue = sanitizedValue.replace(regex, '');
    });

    return sanitizedValue;
  }
}

